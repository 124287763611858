<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong>{{currentUser.username}}</strong> Profile
      </h3>
    </header>
    <div class="row">
      <div class="col-6">
          <billing-address />
      </div>
      <div class="col-6">
        <shipping-address />
      </div>
      <div class="col-12 mt-4">
        <div class="card">
          <div class="card-body">
            <p>
              <strong>Id:</strong>
              {{currentUser.id}}
            </p>
            <p>
              <strong>Email:</strong>
              {{currentUser.email}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingAddress from "./user/billingAddress";
import ShippingAddress from "./user/shippingAddress";
export default {
  name: 'Profile',
  components: {ShippingAddress, BillingAddress},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>
